import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import style from './style';
import { hidePopup } from '../../../redux/api/actions';
import getNumEnding from '../../../utils/getNumEnding';
import generateBrand from '../../../utils/generateBrand';
import { detailPage } from '../../../utils/links';
import * as propTypes from '../../../propTypes';

import Icon from '../../../components/Icon';
import Table from '../../components/Table';
import Price from '../../../components/Price';
import Button from '../../components/Button';

const quantityEndings = [
  ' товар',
  ' товара',
  ' товаров',
];

class CartPopupContainer extends Component {
  static propTypes = {
    cartLoad: PropTypes.bool,
    cart:     propTypes.cart,
    dispatch: PropTypes.func,
    visible:  PropTypes.bool,
    item:     propTypes.cart,
  };

  static prevent(e) {
    e.preventDefault();
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      needXscroll: false,
    };

    this.onClose = this.onClose.bind(this);
    this.showXscrollbar = this.showXscrollbar.bind(this);
  }

  componentDidMount() {
    /* eslint-disable */
    this.body = document.getElementsByTagName('body')[0];
    this.setState({ needXscroll: this.hasScrollBar() });
    /* eslint-enable */
  }

  componentWillReceiveProps(nextProps) {
    if (process.env.CLIENT) {
      if (nextProps.visible) {
        this.body.classList.add('hidden');
        this.body.style.marginRight = `${this.getScrollbarSize()}px`;
        this.body.addEventListener('touchmove', this.prevent);
        window.addEventListener('resize', this.showXscrollbar);
        setTimeout(() => this.showXscrollbar());
        this.setState({ needXscroll: this.hasScrollBar() });
      } else {
        this.body.classList.remove('hidden');
        this.body.style.marginRight = 0;
        this.body.removeEventListener('touchmove', this.prevent);
        window.removeEventListener('resize', this.showXscrollbar);
      }
    }
  }

  onClose() {
    this.props.dispatch(hidePopup());
  }

  // https://github.com/dimsemenov/Magnific-Popup/blob/master/src/js/core.js#L787
  getScrollbarSize() {
    if (this.scrollbarSize === undefined) {
      const scrollDiv = document.createElement('div');
      scrollDiv.style.cssText = 'width: 99px; height: 99px; overflow: scroll; position: absolute; top: -9999px;';
      document.body.appendChild(scrollDiv);
      this.scrollbarSize = scrollDiv.offsetWidth - scrollDiv.clientWidth;
      document.body.removeChild(scrollDiv);
    }
    return this.scrollbarSize;
  }

  showXscrollbar() {
    this.setState({ needXscroll: this.hasScrollBar() });
  }

  hasScrollBar() {
    if (process.env.CLIENT) {
      if (!this.wrapper) return false;
      return this.wrapper.offsetWidth > window.innerWidth;
    }
    return false;
  }

  renderTotal() {
    const { cart, cartLoad } = this.props;
    if (cartLoad) return <span className={style.loading} />;
    const { quantity } = cart.total;
    const total = cart.total.summ;
    return (
      <span>
        {quantity}
        {getNumEnding(quantity, quantityEndings)}{' '}
        на сумму
        <span className={style.bold}> <Price value={total} /></span>
      </span>
    );
  }

  render() {
    const items = this.props.item ? [this.props.item] : [];
    const cols = [
      { title: 'Артикул' },
      { title: 'Ориг. номер' },
      { title: 'Название' },
      { title: 'Сост.' },
      { title: 'Цена', classNames: ['right'] },
    ];

    const rows = items.map(item => [
      item.barcode,
      item.kod,
      <Link className={style.link} to={detailPage(item)}>
        {item.partName ? item.partName.title : null} {generateBrand(item.relatedBrand)}
      </Link>,
      item.used ? 'Б/у' : 'Новая',
      <Price value={item.sellingPrice} />,
    ]);

    if (!this.props.visible) return null;

    return (
      <div className={style.wrapper}>
        <div
          className={style.overlay}
          onClick={this.onClose}
          style={{ bottom: this.state.needXscroll ? this.getScrollbarSize() : 0 }}
        />
        <div ref={(c) => { this.wrapper = c; }} className={style.popup_wrapper}>
          <div className={style.popup}>
            <div className={style.close} onClick={this.onClose}>
              <Icon glyph="close" className={style.close_icon} />
            </div>
            <div className={style.title}>Товар успешно добавлен в корзину!</div>
            <Table rows={rows} cols={cols} />
            <div className={style.footer}>
              <span className={style.total}>
                <span className={style.bold}>В корзине: </span>
                <span className={style.total_inner}>
                  {this.renderTotal()}
                </span>
              </span>
              <span className={style.button}>
                <Button name="popup-goto-cart" tag="a" to="/cart" filled onClick={this.onClose}>
                  Перейти в корзину
                </Button>
              </span>
              <span className={style.button}>
                <Button name="popup-close" onClick={this.onClose} role="button">Продолжить покупки</Button>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    visible:  state.cartPopup.visible,
    item:     state.cartPopup.item,
    cart:     state.cart,
    cartLoad: state.cartLoad,
  };
}

export default connect(mapStateToProps)(CartPopupContainer);
