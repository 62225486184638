/* eslint-disable */
/**
 * Функция возвращает окончание для множественного числа слова на основании числа и массива окончаний
 * param  iNumber Integer Число на основе которого нужно сформировать окончание
 * param  aEndings Array Массив слов или окончаний для чисел (1, 4, 5),
 *         например ['яблоко', 'яблока', 'яблок']
 * return String
 */
export default function getNumEnding(iNumber, aEndings) {
  const iEnding = iNumber % 100;
  let sEnding;
  let i;

  if (iEnding >= 11 && iEnding <= 19) {
    sEnding = aEndings[2];
  } else {
    i = iEnding % 10;
    switch (i) {
      case 1: sEnding = aEndings[0]; break;
      case 2:
      case 3:
      case 4: sEnding = aEndings[1]; break;
      default: sEnding = aEndings[2];
    }
  }
  return sEnding;
}
