import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './style';

function Table({ condensed, cols, rows, loading, children }) {
  const cellClass = classNames(
    style.cell,
    { [style.condensed]: condensed }
  );

  const rowClasses = cols.map(col => (col.classNames || []).map(cn => style[cn]));

  return (
    <table className={classNames(style.table, { [style.loading]: loading })}>
      <thead className={style.header}>
        <tr>
          {cols.map((col, i) => (
            <th key={col.key || col.title} className={classNames(style.header_cell, rowClasses[i])}>{col.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, i) => (
          <tr key={i}>
            {row.map((cell, j) => {
              if (cell === undefined) return null;
              return <td key={j} className={classNames(cellClass, rowClasses[j])}>{cell}</td>;
            })}
          </tr>
        ))}
        {children}
      </tbody>
    </table>
  );
}

Table.propTypes = {
  cols:      PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
  })),
  rows:      PropTypes.arrayOf(PropTypes.any),
  loading:   PropTypes.bool,
  condensed: PropTypes.bool,
  children:  PropTypes.element,
};

Table.defaultProps = {
  cols:      [],
  rows:      [],
  loading:   false,
  condensed: false,
};

export default Table;
